import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Input(theme: Theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: theme.typography.body2.fontSize,
          fontWeight: 400,
          borderColor: theme.palette.divider,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.divider,
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
              borderColor: theme.palette.primary.main,
            },
          },
          color: theme.palette.text.primary,
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: false,
      },
    },
  };
}
