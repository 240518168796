import {
  createContext,
  useEffect,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  signOut,
  onAuthStateChanged,
  signInWithPopup,
  signInWithCustomToken,
  getAuth,
  GoogleAuthProvider,
  User,
} from 'firebase/auth';
import firebaseApp from '@core/firebase';
import { useGetMe } from '@core/services/users';
import { ContextState } from './types';
import { UserFull } from '@core/@types/users';

const firebaseAuth = getAuth(firebaseApp);

export const AuthContext = createContext<ContextState | null>(null);

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [firebaseUser, setFirebaseUser] = useState<User | null>(null);
  const { data: user, mutate: mutateUser } = useGetMe(firebaseUser != null);
  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (user && !initialized) {
      setInitialized(true);
    }
  }, [initialized, user]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, async (fUser) => {
      if (fUser) {
        setFirebaseUser(fUser);
      } else {
        setInitialized(true);
      }

      return unsubscribe;
    });

    unsubscribe();
  }, []);

  const refreshUser = useCallback(async () => {
    await mutateUser();
  }, [mutateUser]);

  const logout = useCallback(async () => {
    await signOut(firebaseAuth);
    setFirebaseUser(null);
  }, []);

  const signInWithGoogle = useCallback(async () => {
    try {
      const fUser = await signInWithPopup(
        firebaseAuth,
        new GoogleAuthProvider()
      );
      setFirebaseUser(fUser.user);
    } catch (err) {
      /* empty */
    }
  }, []);

  const signInWithToken = useCallback(async (token: string) => {
    const fUser = await signInWithCustomToken(firebaseAuth, token);
    setFirebaseUser(fUser.user);
  }, []);

  const memoizedValue = useMemo(
    () => ({
      user:
        firebaseUser && user
          ? (user as UserFull)
          : (undefined as unknown as UserFull),
      initialized,
      refreshUser,
      signInWithToken,
      signInWithGoogle,
      logout,
    }),
    [
      firebaseUser,
      user,
      initialized,
      refreshUser,
      signInWithToken,
      signInWithGoogle,
      logout,
    ]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}
