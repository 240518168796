import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import { ContextState, Status } from './types';
import { OrganisationMember } from '@core/@types/organisations';
import { useGetOrganisationsMe } from '@core/services/organisations';
import { useAuth } from '@core/auth';

export const OrganisationsContext = createContext<ContextState | null>(null);

type ProviderProps = {
  children: React.ReactNode;
};

export function OrganisationsProvider({ children }: ProviderProps) {
  const { query } = useRouter();
  const { user, initialized } = useAuth();
  const { data, isLoading, error, mutate } = useGetOrganisationsMe(
    initialized && user != null
  );
  const [status, setStatus] = useState<Status>('loading');

  useEffect(() => {
    if (isLoading) {
      setStatus('loading');
    } else if (error) {
      setStatus('failed');
    } else if (data) {
      setStatus('success');
    }
  }, [isLoading, data, error]);

  const getCurrentOrganisationMember = useCallback((): OrganisationMember => {
    return data?.organisationMembers.find(
      (organisationWithMember) =>
        organisationWithMember.organisation.id === query.organisationId
    ) as OrganisationMember;
  }, [query.organisationId, data]);

  const refresh = useCallback(async () => {
    await mutate();
  }, [mutate]);

  const memoizedValue = useMemo(
    () => ({
      status,
      getCurrentOrganisationMember,
      organisationMembers: data?.organisationMembers || [],
      refresh,
    }),
    [status, getCurrentOrganisationMember, data?.organisationMembers, refresh]
  );

  return (
    <OrganisationsContext.Provider value={memoizedValue}>
      {children}
    </OrganisationsContext.Provider>
  );
}
