import { ENV } from '@core/config';

export const ROUTES = {
  landing: 'https://www.bowld.ai',
  privacyPolicy: 'https://www.bowld.ai/privacy-policy',
  termsOfUse: 'https://www.bowld.ai/privacy-policy',
  termsApplicants: 'https://www.bowld.ai/terms',
  root: '/',
  signIn: '/login',
  signUp: '/sign-up',
  logout: '/logout',
  onboarding: '/onboarding',
  resetPassword: '/reset-password',
  organisations: {
    root: `/org`,
    rootId: (organisationId: string) => `/org/${organisationId}`,
    forms: (organisationId: string) => `/org/${organisationId}/forms`,
    settings: {
      root: (organisationId: string) => `/org/${organisationId}/settings`,
      account: (organisationId: string) =>
        `/org/${organisationId}/settings/account`,
      members: (organisationId: string) =>
        `/org/${organisationId}/settings/members`,
      organisation: (organisationId: string) =>
        `/org/${organisationId}/settings/organisation`,
      teams: (organisationId: string) =>
        `/org/${organisationId}/settings/teams`,
    },
  },
  forms: {
    shareUrl: (id: string, locale: string) => `${ENV.URL}/${locale}/${id}`,
    id: (id: string, preview?: boolean) =>
      `/${id}${preview ? '?preview=true' : ''}`,
  },
  app: {
    root: '/app',
    home: '/app/home',
    settings: '/app/settings',
    settingsProfile: '/app/settings/?tab=profile',
    settingsCompany: '/app/settings/?tab=company',
    jobs: '/app/jobs',
    jobNew: '/app/jobs/new/',
    jobEdit: (id: string) => `/app/jobs/${id}/edit/`,
    jobPreview: (id: string) => `/app/jobs/${id}/preview/`,
    jobDetail: (id: string) => `/app/jobs/${id}/`,
    jobApplicant: (jobId: string, applicantId: string) =>
      `/app/jobs/${jobId}/applicants/${applicantId}`,
  },
  jobs: {
    apply: (companyId: string, jobId: string) => `/${jobId}`,
  },
};
