export const CONSTANTS = {
  APP_NAME: 'Bowld',
  MEDIAS: {
    faviconPath: '/logo.png',
    defaultLogoUrl:
      'https://storage.googleapis.com/bowld-prod-medias/fLCaDaIB7RMGzB9XvnFSo.png',
    defaultSharePreviewUrl:
      'https://storage.googleapis.com/bowld-prod-medias/B0DNJmmx02cP3jwnEn98U.png',
    defaultVideoBackgroundUrl:
      'https://storage.googleapis.com/bowld-prod-medias/CCGedS9EIAaH10avyLDcW.mp4',
  },
};
