import { forwardRef } from 'react';
// icons
import { Icon } from '@iconify/react';
// @mui
import { Box, BoxProps, useTheme } from '@mui/material';
//
import { IconifyProps } from './types';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  icon: IconifyProps;
}

const Iconify = forwardRef<SVGElement, Props>(
  (
    // eslint-disable-next-line react-hooks/rules-of-hooks
    { icon, width = 18, color = useTheme().palette.grey[500], sx, ...other },
    ref
  ) => (
    <Box
      ref={ref}
      component={Icon}
      icon={icon}
      sx={{
        width,
        height: width,
        color,
        ...sx,
      }}
      {...other}
    />
  )
);

export default Iconify;
