import axios from 'axios';
import { firebaseAuth } from '@core/firebase';
import { ENV } from '@core/config';

export const createAuthHeaders = (token?: string) => {
  return {
    Authorization: `Bearer ${token}`,
  };
};

const axiosInstance = axios.create({
  baseURL: ENV.API_URL,
  timeout: 60000,
});

export const globalAxiosInstance = axios.create({
  timeout: 60000,
});

export const fetcher = (url: string) =>
  axiosInstance.get(url).then((res) => res.data);

axiosInstance.interceptors.response.use(
  async (response) => {
    if (!ENV.IS_PRODUCTION) {
      // eslint-disable-next-line no-console
      console.info('API - Response - Success', {
        status: response?.status,
        url: response?.config?.url,
        method: response?.config?.method,
      });
    }
    return response;
  },
  (error) => {
    if (!ENV.IS_PRODUCTION) {
      // eslint-disable-next-line no-console
      console.info('API - Response - Error', {
        url: error?.config?.url,
        method: error?.config?.method,
        status: error?.response?.status,
        data: error?.response?.data,
        raw: error?.response?.status ? undefined : error,
      });
    }
    throw error;
  }
);

axiosInstance.interceptors.request.use(
  async (config) => {
    try {
      const currentUserToken = await firebaseAuth.currentUser?.getIdToken();
      const authHeaders = config?.headers?.Authorization
        ? {}
        : createAuthHeaders(currentUserToken);
      config.headers = {
        ...authHeaders,
        ...config.headers,
      };
      // eslint-disable-next-line no-empty
    } catch (err) {}

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default axiosInstance;
