import { ReactNode, useEffect, createContext } from 'react';
import { useRouter } from 'next/router';
import {
  load as loadIntercom,
  boot as bootIntercom,
  update as updateIntercom,
} from './client';

interface Props {
  children: ReactNode;
}

export const Context = createContext(undefined);

export const IntercomProvider = ({ children }: Props) => {
  const router = useRouter();

  if (typeof window !== 'undefined') {
    loadIntercom();
    bootIntercom();
  }

  useEffect(() => {
    const handleRouteChange = () => {
      if (typeof window !== 'undefined') {
        updateIntercom();
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events]);

  return <Context.Provider value={undefined}>{children}</Context.Provider>;
};
