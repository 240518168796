import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export type ColorSchema =
  | 'primary'
  | 'secondary'
  | 'info'
  | 'success'
  | 'warning'
  | 'error';

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
}

// SETUP COLORS

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#EBEBEC',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#313539',
  900: '#161C24',
};

const PRIMARY = {
  lighter: '#191919',
  light: '#0c0c0c',
  main: '#000000',
  dark: '#000000',
  darker: '#000000',
  contrastText: '#fff',
};

const SECONDARY = {
  lighter: '#788de5',
  light: '#677fe2',
  main: '#5771df',
  dark: '#4e65c8',
  darker: '#455ab2',
  contrastText: '#fff',
};

const INFO = {
  lighter: '#b9cbd8',
  light: '#b9cbd8',
  main: '#006C9C',
  dark: '#16537e',
  darker: '#003768',
  contrastText: '#fff',
};

const SUCCESS = {
  lighter: '#D8FBDE',
  light: '#86E8AB',
  main: '#36B37E',
  dark: '#1B806A',
  darker: '#0A5554',
  contrastText: '#fff',
};

const WARNING = {
  lighter: '#fae2c6',
  light: '#fef8eb',
  main: '#f2b934',
  dark: '#9d7b2c',
  darker: '#f09f44',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FFE9D5',
  light: '#FFAC82',
  main: '#FF5630',
  dark: '#B71D18',
  darker: '#7A0916',
  contrastText: '#fff',
};

export default function palette() {
  return {
    common: { black: '#000', white: '#fff' },
    primary: PRIMARY,
    secondary: SECONDARY,
    info: INFO,
    success: SUCCESS,
    warning: WARNING,
    error: ERROR,
    grey: GREY,
    divider: alpha(GREY[500], 0.4),
    action: {
      hover: GREY[200],
      selected: alpha(GREY[500], 0.16),
      disabled: alpha(GREY[500], 0.8),
      disabledBackground: alpha(GREY[500], 0.24),
      focus: alpha(GREY[500], 0.24),
      hoverOpacity: 0.08,
      disabledOpacity: 0.48,
      active: GREY[600],
    },
    text: {
      primary: PRIMARY.main,
      secondary: GREY[600],
      disabled: GREY[500],
    },
    background: { paper: '#fff', default: '#fff', neutral: '#f0f0f0' },
  };
}
