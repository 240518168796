import { initializeApp, getApp, getApps } from 'firebase/app';
import {
  getAuth,
  sendPasswordResetEmail as sendPasswordResetEmailFirebase,
} from 'firebase/auth';
import { ENV } from '@core/config';

const app = getApps().length === 0 ? initializeApp(ENV.FIREBASE) : getApp();

export const firebaseAuth = getAuth(app);

export const sendPasswordResetEmail = sendPasswordResetEmailFirebase;

export default app;
