import { GlobalStyles as MUIGlobalStyles, useTheme } from '@mui/material';

export default function GlobalStyles() {
  const theme = useTheme();

  const borderRadius = `${theme.shape.borderRadius}px`;

  const inputGlobalStyles = (
    <MUIGlobalStyles
      styles={{
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
        },
        '#__next': {
          width: '100%',
          height: '100%',
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
        },
        img: {
          display: 'block',
          maxWidth: '100%',
        },
        ul: {
          margin: 0,
          padding: 0,
        },
        '.ql-toolbar.ql-snow': {
          borderRadius: `${borderRadius} ${borderRadius} 0px 0px`,
          borderColor: theme.palette.divider,
        },
        '.ql-container.ql-snow': {
          borderRadius: `0px 0px ${borderRadius} ${borderRadius}`,
          borderColor: theme.palette.divider,
          minHeight: '120px',
          fontFamily: theme.typography.body2.fontFamily,
        },
      }}
    />
  );

  return inputGlobalStyles;
}
