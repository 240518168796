import useSWR from 'swr';
import {
  OrganisationMemberResponse,
  OrganisationMemberRole,
  OrganisationMembersResponse,
  OrganisationResponse,
} from '@core/@types/organisations';
import axios, { fetcher } from '@core/utils/axios';

export const useGetOrganisationsMe = (shouldPerform: boolean) => {
  const { data, error, isLoading, mutate } =
    useSWR<OrganisationMembersResponse>(
      shouldPerform ? `/organisations/me` : null,
      fetcher
    );

  return {
    data,
    mutate,
    isLoading,
    error: !data && error ? error : undefined,
  };
};

export const useGetOrganisationMembers = (organisationId: string) => {
  const { data, error, isLoading, mutate } =
    useSWR<OrganisationMembersResponse>(
      `/organisations/${organisationId}/members`,
      fetcher
    );

  return {
    data,
    mutate,
    isLoading,
    error: !data && error ? error : undefined,
  };
};

export const createOrganisation = async (
  name: string
): Promise<OrganisationResponse> => {
  const response = await axios.post('/organisations', {
    name,
  });
  return response.data;
};

export const updateOrganisation = async (
  organisationId: string,
  name: string,
  logoUrl?: string
): Promise<OrganisationResponse> => {
  const response = await axios.put(`/organisations/${organisationId}`, {
    name,
    logoUrl,
  });
  return response.data;
};

export const createOrganisationMember = async (
  organisationId: string,
  firstName: string,
  lastName: string,
  email: string,
  role: OrganisationMemberRole
): Promise<OrganisationMemberResponse> => {
  const response = await axios.post(
    `/organisations/${organisationId}/members`,
    {
      firstName,
      lastName,
      email,
      role,
    }
  );

  return response.data;
};

export const updateOrganisationMember = async (
  organisationId: string,
  organisationMemberId: string,
  role: OrganisationMemberRole,
  isActive: boolean
): Promise<OrganisationMemberResponse> => {
  const response = await axios.put(
    `/organisations/${organisationId}/members/${organisationMemberId}`,
    {
      isActive,
      role,
    }
  );

  return response.data;
};
