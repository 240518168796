import { useContext } from 'react';
import { OrganisationsContext } from './provider';

export const useOrganisations = () => {
  const context = useContext(OrganisationsContext);

  if (!context)
    throw new Error(
      'useOrganisations context must be used inside OrganisationsContext'
    );

  return context;
};
