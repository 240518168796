import { useContext } from 'react';
import { Context } from './provider';

const useAnalytics = () => {
  const context = useContext(Context);

  if (!context)
    throw new Error(
      'useAnalytics context must be used inside AnalyticsProvider'
    );

  return context;
};

export default useAnalytics;
